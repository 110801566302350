import '../css/GeneralCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import '../css/HomeCSS.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function HomeButton() {
  return (
    <button className='homeButtonArea'>
       <i className="bi bi-house-door-fill homeIcon"></i>
    </button>
  );
}

export default HomeButton;
