import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/ActivityBuilder.css'
import '../css/GeneralCSS.css'
import '../css/Home.css'
import '../css/Text.css'
import '../css/Colors.css'
import ExerciseListElement from '../BaseElements/ExerciseListElement.js';
import PatientBubble from '../BaseElements/PatientBubble.js';
import TopBar from '../BaseElements/TopBar/TopBar.js'

import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import isLoggedIn from '../code/utils.js'
import axios from 'axios';
import downloadLogs from "../BaseElements/DownloadLogs";

axios.defaults.withCredentials = true;

function ActivityInfo() {
    const {activityId} = useParams();
    const navigate = useNavigate();
    const [activityName, setActivityName] = useState('');
    const [exerciseList, setExerciseList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [selectedPatients, setSelectedPatients] = useState([]);
    
    const username = localStorage.getItem('username');
    
    useEffect(() => {
        loadData().then();
    }, [navigate]);
    
    const loadData = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const activityResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getActivity`,
                {activity_id: activityId},
                axiosConfig
            );
            const exercisesResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getActivityData`,
                {activity_id: activityId},
                axiosConfig
            );
            const selectedPatientResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getPatientsOfTherapistActivity`,
                {activity_id: activityId},
                axiosConfig
            );
            const patientsResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/caregiverPatients`,
                axiosConfig
            );
            
            if (exercisesResponse.data.success && activityResponse.data.success && selectedPatientResponse.data.success && patientsResponse.data.success) {
                const activity = activityResponse.data.data;
                const exercises = exercisesResponse.data.data;
                const selPatients = selectedPatientResponse.data.data;
                const patients = patientsResponse.data.data;
                let temp1 = [];
                let temp2 = [];
                let temp3 = [];
                exercises.forEach(exercise => {
                    temp1.push({id: exercise[0], name: exercise[1], type: exercise[2], level: exercise[3]});
                });
                selPatients.forEach(patient => {
                    temp2.push({"id":patient[0].toString(), "username":patient[1].toString().toUpperCase()});
                });
                patients.forEach(patient => {
                    temp3.push({"id":patient[0].toString(), "username":patient[1].toString().toUpperCase()});
                })
                setActivityName(activity[0][2]);
                setExerciseList(temp1);
                setSelectedPatients(temp2);
                setPatientList(temp3);
            } else {
                console.error(activityResponse.data.errorMess);
                console.error(exercisesResponse.data.errorMess);
                console.error(selectedPatientResponse.data.errorMess);
                console.error(patientsResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const logout = async () => {
        if (!isLoggedIn())
            navigate("/")
        else {
            //send req to logout
            const axiosConfig = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            try {
                //setLoading(true)
                const response = await axios.get('http://localhost:5000/api/logOut', {}, axiosConfig);
                // setLoading(false);
                if (response.data.success) {
                    //if in every case (besides errors) just log out
                    console.log(localStorage.getItem('user'))
                    localStorage.removeItem('user')
                    console.log("removed? :" + localStorage.getItem('user'))
                    navigate("/")
                } else {
                    alert(response.data.errorMess)
                }
            } catch (error) {
                alert(error.errorMess);
            }
        }
    }
    
    
    function addUserToActivity(event, username) {

        if (event.code !== 'Enter' || !username) return;
        
        // retrieve user
        const user = patientList.find(patient => patient.username.toUpperCase() === username.toUpperCase());

        // check if user does exist and if they are not already assigned to the activity
        if (!user) return;
        if (selectedPatients.some(patient => patient.username.toUpperCase() === user.username.toUpperCase())) return;

        // add the patient to the list
        setSelectedPatients(prev => [...prev, { id: user.id, username: user.username.toUpperCase()}]);

        updatePatientsOfActivity(user.id).then();
    }
    
    const updatePatientsOfActivity = async (user) => {
        
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const userList = [user];
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/associatePatientsToActivity`,
                {activity_id: activityId, patient_ids: userList},
                axiosConfig
            );
            
            if (!response.data.success) {
                console.error(response.data.errorMess)
            }
        } catch (error) {
            console.error(error.errorMess);
        }
    }
    
    const getLogs = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getLogsOfActivity`,
                {activity_id: activityId},
                axiosConfig
            );
            
            if (response.data.success) {
                const logs = response.data.data;
                downloadLogs(logs);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    return (
        <div className='fullPageContainer'>
            <TopBar text={username} onMenu={() => {
                navigate('/home')
            }} onLogout={logout} showSearchBar={false}></TopBar>
            <div className='exitTitleArea'>
                <div>{activityName}</div>
            </div>
            <div className='activityMainContent'>
                <div className='activityHalfContentColumn '>
                    <div className='infoContainer'>
                        <div className='infoArea'>
                            <div>{activityName}</div>
                        </div>
                        <div className='infoAreaPatients'>
                            <div>Pazienti Assegnati</div>
                            <div className='patientsInfo'>
                                {selectedPatients.map((user) => (
                                    <PatientBubble key={user.id} patientCode={user.username} canRemove={false} onClick={() => {}}></PatientBubble>
                                ))}
                            </div>
                            <p>Aggiungi paziente:</p>
                            <input className="assignPatientBar" onKeyDown={(e) => {
                                addUserToActivity(e, e.target.value)
                            }}/>
                        </div>
                        <div className='infoArea'>
                            <p>Dati attività</p>
                            <button className='downloadButton' onClick={getLogs}>Scarica dati</button>
                        </div>
                    </div>
                </div>
                <div className='activityHalfContentColumn '>
                    <div className='exercisesList'>
                        {exerciseList.map((exercise) => (
                            <ExerciseListElement key={exercise.id} name={exercise.name} type={exercise.type} level={exercise.level}/>))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActivityInfo;
