import React, {useEffect, useState} from 'react';
import '../App.css';
import '../css/Home.css';
import '../css/Colors.css';
import '../css/GeneralCSS.css';
import '../css/Text.css';

import ActivityFilter from '../BaseElements/Filters/ActivityFilter.js';
import PatientFilter from '../BaseElements/Filters/PatientsFilter.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import PatientCard from '../BaseElements/Cards/PatientCard.js';
import ActivityCard from '../BaseElements/Cards/ActivityCard.js';
import {useNavigate} from 'react-router-dom';
import isLoggedIn from '../code/utils';
import axios from 'axios';
import TopBar from '../BaseElements/TopBar/TopBar.js';
import NewCard from "../BaseElements/Buttons/NewCardButton";
import {Button, Modal} from 'react-bootstrap';
import downloadLogs from "../BaseElements/DownloadLogs";

axios.defaults.withCredentials = true;

function HomePage() {
    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [, setActivities] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [showListPatients, setShowListPatients] = useState(false);
    const [showListActivities, setShowListActivities] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [showModal, setShowModal] = useState(false); // State to control the modal visibility
    const [showModalActivity, setShowModalActivity] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null); // State to store the selected patient for deletion
    const [selectedActivity, setSelectedActivity] = useState(null); // State to store the selected activity for deletion
    
    const username = localStorage.getItem('username');
    
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/");
            return;
        }
        loadData().then();
    }, [navigate]);
    
    const loadData = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const activitiesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverActivities`, axiosConfig);
            const patientsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverPatients`, axiosConfig);
            
            if (activitiesResponse.data.success && patientsResponse.data.success) {
                const activitiesTemp = activitiesResponse.data.data;
                const patientsTemp = patientsResponse.data.data;
                const activitiesMapped = activitiesTemp.map((element) => ({
                    id: element[0],
                    name: element[1],
                    numOfExercises: element[2],
                }));
               
                const patientsMapped = patientsTemp.map((element) => ({
                    id: element[0].toString(),
                    username: element[1].charAt(0).toUpperCase() + element[1].slice(1),
                  }));

                setActivityData(activitiesMapped);
                setPatients(patientsMapped);
                setActivities(activitiesMapped.map((activity) => activity.name));
            } else {
                console.error(activitiesResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    async function deletePatient(id) {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const patientsResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/deletePatient`,
                {patient_id: id},
                axiosConfig
            );
            
            if (patientsResponse.data.success) {
                console.log(patientsResponse.data.message);
                
                // Reload the patient list after deletion
                const updatedPatientsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverPatients`, axiosConfig);
                if (updatedPatientsResponse.data.success) {
                    const patientsTemp = updatedPatientsResponse.data.data.map((element) => ({
                        id: element[0].toString(),
                        username: element[1].charAt(0).toUpperCase() + element[1].slice(1),
                      }));
                    setPatients(patientsTemp);
                } else {
                    console.error(updatedPatientsResponse.data.errorMess);
                }
            } else {
                console.error(patientsResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    const getAllLogsOfPatient = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getAllLogsOfPatient`,
                {patient_id: selectedPatient},
                axiosConfig
            );
            if (response.data.success) {
                const logs = response.data.data;
                downloadLogs(logs);
            } else {
                console.error(response.data.errorMess);
            }
        } catch (error) {
            console.error(error.errorMess);
        }
    }
    
    const getAllLogsOfActivity = async () =>{
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getLogsOfActivity`,
                {activity_id: selectedActivity},
                axiosConfig
            );
            
            if (response.data.success) {
                const logs = response.data.data;
                downloadLogs(logs);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const UpdateSearchValue = (searchText) => {
        setSearchValue(searchText);
    };
    
    function manageFilter(patients, activities) {
        setShowListActivities(activities);
        setShowListPatients(patients);
    }
    
    const filteredPatients = patients.filter((patient) =>
        patient.username.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    console.log(filteredPatients)
    const filteredActivities = activityData.filter((activity) =>
        activity.name.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    
    const handleDeleteClick = (patientId) => {
        setSelectedPatient(patientId);
        setShowModal(true);
    };
    
    const handleDeleteClickOnActivity = (activityId) =>{
        setSelectedActivity(activityId);
        setShowModalActivity(true)
    }

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedPatient(null);
    };

    const handleModalActivityClose = () => {
        setShowModalActivity(false);
        setSelectedActivity(null);
    };
    
    const handleModalConfirm = () => {
        if (selectedPatient) {
            deletePatient(selectedPatient).then();
        }
        handleModalClose();
    };

    const handleModalActivityConfirm = () => {
        if (selectedActivity) {
            deleteActivity(selectedActivity).then();
        }
        handleModalActivityClose();
    };

    async function deleteActivity(id) {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const activitiesResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/deleteActivity`,
                {activity_id: id},
                axiosConfig
            );
            if (activitiesResponse.data.success) {
                // Reload the activity list after deletion
                const updatedActivities=  await axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverActivities`, axiosConfig);               
                if (updatedActivities.data.success) {
                    const activitiesTemp = updatedActivities.data.data;
                    const activitiesMapped = activitiesTemp.map((element) => ({
                        id: element[0],
                        name: element[1],
                        numOfExercises: element[2],
                    }));
                    
                    
                    setActivityData(activitiesMapped);  
                    setActivities(activitiesMapped.map((activity) => activity.name)); 
                } else {
                    console.error(updatedActivities.data.errorMess);
                }
            } else {
                console.error(activitiesResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    }


    
    return (
        <div className='fullPageContainer'>
            <TopBar text={username} onChange={UpdateSearchValue} onMenu={() => navigate('/home')} onLogout={() => {
            }} showSearchBar={true}/>
            <div className='filterArea'>
                <ActivityFilter isClicked={showListActivities} onClick={() => manageFilter(false, true)}/>
                <PatientFilter isClicked={showListPatients} onClick={() => manageFilter(true, false)}/>
            </div>
            <div className='homeMainContentArea'>
                <div className='homeScrollingArea'>
                    <div className='homeScrollingListElementView'>
                        {showListPatients && filteredPatients.map((patient) => (
                            <PatientCard key={patient.id} text={patient.username} onOpen={() => navigate(`/patient-info/${patient.id}/${patient.username}`)} onDelete={() => handleDeleteClick(patient.id)}/>
                        ))}
                        {showListActivities && filteredActivities.map((activity) => (
                            <ActivityCard
                                key={activity.id}
                                text={activity.name}
                                exercisesNumber={activity.numOfExercises}
                                onClick={() => navigate(`/activity-info/${activity.id}`)}
                                onDelete={() => handleDeleteClickOnActivity(activity.id)}
                            />
                        ))}
                    </div>
                </div>
                <div className='newCardArea'>
                    {showListPatients && <NewCard text={'Nuovo Paziente'} onClick={() => navigate('/add-user')}/>}
                    {showListActivities && <NewCard text={'Crea Attività'} onClick={() => navigate('/create-activity')}/>}
                </div>
            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sei sicuro di voler eliminare questo paziente e tutti i suoi log? L'azione è irreversibile.
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        style={{backgroundColor: 'var(--filter-hovered-text-color)', borderColor: 'var(--filter-hovered-text-color)'}}
                        onClick={getAllLogsOfPatient}>
                        Scarica Log
                    </Button>
                    <div>
                        <Button
                            style={{
                                backgroundColor: 'red',
                                borderColor: 'red',
                                color: 'white',
                                marginRight: '10px',
                            }}
                            onClick={handleModalClose}>
                            Annulla
                        </Button>
                        <Button
                            style={{
                                backgroundColor: 'var(--filter-hovered-text-color)',
                                borderColor: 'var(--filter-hovered-text-color)',
                            }}
                            onClick={handleModalConfirm}>
                            Conferma
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={showModalActivity} onHide={handleModalActivityClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sei sicuro di voler eliminare questa attività e tutti i suoi log? L'azione è irreversibile.
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        style={{backgroundColor: 'var(--filter-hovered-text-color)', borderColor: 'var(--filter-hovered-text-color)'}}
                        onClick={getAllLogsOfActivity}>
                        Scarica Log
                    </Button>
                    <div>
                        <Button
                            style={{
                                backgroundColor: 'red',
                                borderColor: 'red',
                                color: 'white',
                                marginRight: '10px',
                            }}
                            onClick={handleModalActivityClose}>
                            Annulla
                        </Button>
                        <Button
                            style={{
                                backgroundColor: 'var(--filter-hovered-text-color)',
                                borderColor: 'var(--filter-hovered-text-color)',
                            }}
                            onClick={handleModalActivityConfirm}>
                            Conferma
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default HomePage;
