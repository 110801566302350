import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MaintenancePage from './pages/MaintenancePage';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import Patient from './pages/PatientInfo';
import AddUser from './pages/AddUser';
import CreateActivity from './pages/CreateActivity';
import ActivityInfo from './pages/ActivityInfo';

function App() {
  const isMaintenanceMode = false; // Change to 'false' to restore normal access
  
  return (
      <Router>
        <Routes>
          {isMaintenanceMode ? (
              // Redirect all routes to Maintenance Page
              <Route path="*" element={<MaintenancePage />} />
          ) : (
              <>
                <Route path='/' element={<LoginPage />} />
                <Route path='/home' element={<HomePage />} />
                <Route path='/add-user' element={<AddUser />} />
                <Route path='/create-activity' element={<CreateActivity />} />
                <Route path='/patient-info/:patientId/:patientUsername' element={<Patient />} />
                <Route path='/activity-info/:activityId' element={<ActivityInfo />} />
              </>
          )}
        </Routes>
      </Router>
  );
}

export default App;
