// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeButtonArea{
    color: var(--main-white);
    background-color: var(--main-blue);
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: transparent;
    text-align: center;
    font-size: x-large;

}
`, "",{"version":3,"sources":["webpack://./src/css/HomeCSS.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kCAAkC;IAClC,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;;AAEtB","sourcesContent":[".homeButtonArea{\n    color: var(--main-white);\n    background-color: var(--main-blue);\n    height: 60px;\n    width: 60px;\n    border-radius: 30px;\n    border: transparent;\n    text-align: center;\n    font-size: x-large;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
