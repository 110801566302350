import React from 'react';

function MaintenancePage() {
    return (
        <div className="flex h-screen items-center justify-center bg-gray-100">
            <div className="p-6 bg-white rounded-lg shadow-lg text-center">
                <h1 className="text-3xl font-bold">🚧 Site Under Maintenance 🚧</h1>
                <p className="mt-4 text-gray-700">We are making improvements! Please check back later.</p>
            </div>
        </div>
    );
}

export default MaintenancePage;
