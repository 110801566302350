import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import Patient from './pages/PatientInfo';
import AddUser from './pages/AddUser';
import CreateActivity from './pages/CreateActivity';
import ActivityInfo from './pages/ActivityInfo';

function App() {
  return (
    <Router>
      <Routes >
        <Route path='/'  element={<LoginPage />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/add-user' element={<AddUser/>}/>
        <Route path='/create-activity' element={<CreateActivity/>}/>
        <Route path='/patient-info/:patientId/:patientUsername' element={<Patient/>}/>
        <Route path='/activity-info/:activityId' element={<ActivityInfo/>}/>
      </Routes >
    </Router>
  );
}

export default App;