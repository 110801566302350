import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import Patients from './pages/Patiens';
import AddUser from './pages/AddUser';
import CreateActivity from './pages/CreateActivity';

function App() {
  return (
    <Router>
      <Routes >
        <Route path='/'  element={<LoginPage />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/patients' element={<Patients/>}/>
        <Route path='/addUser' element={<AddUser/>}/>
        <Route path='/createActivity' element={<CreateActivity/>}/>
      </Routes >
    </Router>
  );
}

export default App;