import '../App.css';
import '../css/Patient.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PatientCard from '../BaseElements/Cards/PatientCard.js';
import Filter from '../BaseElements/Filters/Filter.js';
import DownloadButton from '../BaseElements/Buttons/DownloadButton.js';
import TopBar from '../BaseElements/TopBar/TopBar.js';
import ActivityListElement from '../BaseElements/ActivityListElement.js';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import downloadLogs from "../BaseElements/DownloadLogs";
import TitleBarPatient from "../BaseElements/TitleBarPatient";

axios.defaults.withCredentials = true;

function PatientInfo() {
    const {patientId} = useParams();
    const {patientUsername} = useParams();
    const navigate = useNavigate();
    const patient = patientId;
    const [assignedActivities, setAssignedActivities] = useState([]);
    const [showCompleted, setShowCompleted] = useState(true);
    const [showInProgress, setShowInProgress] = useState(false);
    const username = localStorage.getItem('username');
    
    useEffect(() => {
        loadData().then();
    }, [navigate]);
    
    const loadData = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getActivitiesPerPatient`,
                {patient_id: patientId, patient_username:patientUsername},
                axiosConfig
            );
            
            if (response.data.success) {
                const activities = response.data.data;
                let temp = [];
                activities.forEach(activity => {
                    let numOfParts = 0;
                    let correctParts = 0;
                    let numOfExercises = 0;
                    let activitiesDone = 0;
                    activity[2].forEach((item) => {
                        console.log('item: ');
                        console.log(item);
                        if (item.progress === 100) {
                            item.actions.forEach((action) => {
                                if (action.correct) correctParts += 1;
                                numOfParts += 1;
                            });
                            activitiesDone++;
                        }
                        numOfExercises++;
                    });
                    console.log(correctParts);
                    console.log(numOfParts);
                    temp.push({
                                  id: activity[0],
                                  name: activity[3],
                                  progress: activitiesDone,
                                  total: numOfExercises,
                                  percentage: (correctParts / numOfParts) * 100
                              });
                });
                setAssignedActivities(temp);
            } else {
                console.error(response.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    function manageFilter(clicked) {
        switch (clicked) {
            case "completed":
                setShowCompleted(true);
                setShowInProgress(false);
                break;
            case "inProgress":
                setShowCompleted(false);
                setShowInProgress(true);
                break;
            default:
                setShowCompleted(true);
                setShowInProgress(false);
        }
    }
    
    const getAllLogsOfPatient = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getAllLogsOfPatient`,
                {patient_id: patientId},
                axiosConfig
            );
            if (response.data.success) {
                const logs = response.data.data;
                downloadLogs(logs);
            } else {
                console.error(response.data.errorMess);
            }
        } catch (error) {
            console.error(error.errorMess);
        }
    }
    
    const getSingleLog = async (activityId) => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getSingleLog`,
                {patient_id: patientId, activity_id: activityId},
                axiosConfig
            );
            if (response.data.success) {
                const logs = response.data.data;
                downloadLogs(logs);
            } else {
                console.error(response.data.errorMess);
            }
        } catch (error) {
            console.error(error.errorMess);
        }
    }
    
    return (
        <div className='fullPageContainer'>
            <TopBar text={username} onMenu={() => {
                navigate('/home');
            }} onLogout={() => {
            }} showSearchBar={false}></TopBar>
            <div className='patientMainRow'>
                <PatientCard key={patient} info={true} text={patientUsername}/>
                <DownloadButton onClick={getAllLogsOfPatient}/>
            </div>
            <div className='filterArea'>
                <Filter text={"Completate"} isClicked={showCompleted} onClick={() => {
                    manageFilter("completed");
                }}></Filter>
                <Filter text={"In corso"} isClicked={showInProgress} onClick={() => {
                    manageFilter("inProgress");
                }}></Filter>
            </div>
            <div className='viewPatientSessionsRow'>
                {showCompleted && <TitleBarPatient name={'Nome Attività'} download={'Scarica'} answer={'Risposte Corrette'}></TitleBarPatient>}
                {showInProgress &&<TitleBarPatient name={'Nome Attività'} download={'Scarica'} answer={'Esercizi Completati'}></TitleBarPatient>}
                <div className='viewPatientSessionsList'>
                    {showCompleted && assignedActivities.map((activity) =>
                     activity.progress === activity.total &&
                     <ActivityListElement key={activity.id} name={activity.name} percentage={activity.percentage} onClick={() => getSingleLog(activity.id)}/>)}
                    {showInProgress && assignedActivities.map((activity) =>
                     activity.progress !== activity.total &&
                     <ActivityListElement key={activity.id} name={activity.name} progress={`${activity.progress}/${activity.total}`} onClick={() => getSingleLog(activity.id)}/>)}
                </div>
            </div>
        </div>
    );
}

export default PatientInfo;
