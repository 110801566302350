// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center{
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.centerRow{
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}




.cards-image-style{
  height: 40vh;
  filter: invert(91%) sepia(3%) saturate(526%) hue-rotate(197deg) brightness(110%) contrast(96%);
}

.patientsListElement{
  width:-moz-fit-content;
  width:fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 2px;
  margin-top: 2px;
  border-color: transparent;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

.patientsImageListElement{
  width: 15vw;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;AACb;;;;;AAKA;EACE,YAAY;EACZ,8FAA8F;AAChG;;AAEA;EACE,sBAAiB;EAAjB,iBAAiB;EACjB,wBAAmB;EAAnB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".center{\n  word-break: break-all;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  align-content: center;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n.centerRow{\n  word-break: break-all;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  align-content: center;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  width: 100%;\n}\n\n\n\n\n.cards-image-style{\n  height: 40vh;\n  filter: invert(91%) sepia(3%) saturate(526%) hue-rotate(197deg) brightness(110%) contrast(96%);\n}\n\n.patientsListElement{\n  width:fit-content;\n  height: fit-content;\n  margin-bottom: 2px;\n  margin-top: 2px;\n  border-color: transparent;\n  border-radius: 4px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.patientsImageListElement{\n  width: 15vw;\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
