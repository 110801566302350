import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/ActivityBuilder.css';
import '../css/GeneralCSS.css';
import '../css/Home.css';
import '../css/Text.css';
import '../css/Colors.css';

import ExerciseListElement from '../BaseElements/ExerciseListElement.js';
import PatientBubble from '../BaseElements/PatientBubble.js';
import TopBar from '../BaseElements/TopBar/TopBar.js';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import isLoggedIn from '../code/utils.js';
import downloadLogs from "../BaseElements/DownloadLogs";

import { Button, Modal } from 'react-bootstrap'; // <-- Import from react-bootstrap

axios.defaults.withCredentials = true;

function ActivityInfo() {
    const { activityId } = useParams();
    const navigate = useNavigate();
    
    // Existing state
    const [activityName, setActivityName] = useState('');
    const [exerciseList, setExerciseList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [selectedPatients, setSelectedPatients] = useState([]);
    
    const username = localStorage.getItem('username');
    
    // State for removal confirmation
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [patientToRemove, setPatientToRemove] = useState(null);
    
    useEffect(() => {
        loadData();
    }, [navigate]);
    
    const loadData = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const activityResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getActivity`,
                { activity_id: activityId },
                axiosConfig
            );
            const exercisesResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getActivityData`,
                { activity_id: activityId },
                axiosConfig
            );
            const selectedPatientResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getPatientsOfTherapistActivity`,
                { activity_id: activityId },
                axiosConfig
            );
            const patientsResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/caregiverPatients`,
                axiosConfig
            );
            
            if (
                exercisesResponse.data.success &&
                activityResponse.data.success &&
                selectedPatientResponse.data.success &&
                patientsResponse.data.success
            ) {
                const activity = activityResponse.data.data;
                const exercises = exercisesResponse.data.data;
                const selPatients = selectedPatientResponse.data.data;
                const patients = patientsResponse.data.data;
                
                const temp1 = exercises.map(exercise => ({
                    id: exercise[0],
                    name: exercise[1],
                    type: exercise[2],
                    level: exercise[3]
                }));
                
                const temp2 = selPatients.map(patient => ({
                    id: patient[0].toString(),
                    username: patient[1].toString().toUpperCase()
                }));
                
                const temp3 = patients.map(patient => ({
                    id: patient[0].toString(),
                    username: patient[1].toString().toUpperCase()
                }));
                
                setActivityName(activity[0][2]);
                setExerciseList(temp1);
                setSelectedPatients(temp2);
                setPatientList(temp3);
            } else {
                console.error(activityResponse.data.errorMess);
                console.error(exercisesResponse.data.errorMess);
                console.error(selectedPatientResponse.data.errorMess);
                console.error(patientsResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const logout = async () => {
        if (!isLoggedIn()) {
            navigate("/");
        } else {
            // send req to logout
            const axiosConfig = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            try {
                const response = await axios.get(
                    'http://localhost:5000/api/logOut',
                    {},
                    axiosConfig
                );
                if (response.data.success) {
                    localStorage.removeItem('user');
                    navigate("/");
                } else {
                    alert(response.data.errorMess);
                }
            } catch (error) {
                alert(error.errorMess);
            }
        }
    };
    
    function addUserToActivity(event, username) {
        if (event.code !== 'Enter' || !username) return;
        
        // retrieve user
        const user = patientList.find(
            patient => patient.username.toUpperCase() === username.toUpperCase()
        );
        
        // check if user does exist and if they are not already assigned
        if (!user) return;
        if (
            selectedPatients.some(
                patient => patient.username.toUpperCase() === user.username.toUpperCase()
            )
        ) return;
        
        // add the patient to the state
        setSelectedPatients(prev => [
            ...prev,
            { id: user.id, username: user.username.toUpperCase() }
        ]);
        
        // call API to update
        updatePatientsOfActivity(user.id);
    }
    
    const updatePatientsOfActivity = async (userId) => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const userList = [userId];
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/associatePatientsToActivity`,
                { activity_id: activityId, patient_ids: userList },
                axiosConfig
            );
            
            if (!response.data.success) {
                console.error(response.data.errorMess);
            }
        } catch (error) {
            console.error(error.errorMess);
        }
    };
    
    // Function to open modal and set the chosen patient
    const openRemoveModal = (patient) => {
        setPatientToRemove(patient);
        setShowRemoveModal(true);
    };
    
    // Function to confirm removing the patient
    const confirmRemovePatient = async () => {
        if (!patientToRemove) return;
        
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        
        try {
            // Call the API to remove the patient from the activity
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/removeActivityToPatient`,
                {
                    activity_id: activityId,
                    patient_id: patientToRemove.id
                },
                axiosConfig
            );
            
            if (response.data.success) {
                // Remove the patient locally
                setSelectedPatients(prev =>
                                        prev.filter(p => p.id !== patientToRemove.id)
                );
            } else {
                console.error(response.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        } finally {
            // Close the modal
            setShowRemoveModal(false);
            setPatientToRemove(null);
        }
    };
    
    // Function to cancel removal
    const cancelRemovePatient = () => {
        setShowRemoveModal(false);
        setPatientToRemove(null);
    };
    
    const getLogs = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/getLogsOfActivity`,
                { activity_id: activityId },
                axiosConfig
            );
            
            if (response.data.success) {
                const logs = response.data.data;
                downloadLogs(logs);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <div className='fullPageContainer'>
            <TopBar
                text={username}
                onMenu={() => navigate('/home')}
                onLogout={logout}
                showSearchBar={false}
            />
            
            <div className='exitTitleArea'>
                <div>{activityName}</div>
            </div>
            
            <div className='activityMainContent'>
                <div className='activityHalfContentColumn '>
                    <div className='infoContainer'>
                        <div className='infoArea'>
                            <div>{activityName}</div>
                        </div>
                        
                        <div className='infoAreaPatients'>
                            <div>Pazienti Assegnati</div>
                            <div className='patientsInfo'>
                                {selectedPatients.map((user) => (
                                    <PatientBubble
                                        key={user.id}
                                        patientCode={user.username}
                                        canRemove={true}
                                        // Use a callback to open the remove modal
                                        onRemove={() => openRemoveModal(user)}
                                    />
                                ))}
                            </div>
                            <p>Aggiungi paziente:</p>
                            <input
                                className="assignPatientBar"
                                onKeyDown={(e) => addUserToActivity(e, e.target.value)}
                            />
                        </div>
                        
                        <div className='infoArea'>
                            <p>Dati attività</p>
                            <button className='downloadButton' onClick={getLogs}>Scarica dati</button>
                        </div>
                    </div>
                </div>
                
                <div className='activityHalfContentColumn '>
                    <div className='exercisesList'>
                        {exerciseList.map((exercise) => (
                            <ExerciseListElement
                                key={exercise.id}
                                name={exercise.name}
                                type={exercise.type}
                                level={exercise.level}
                            />
                        ))}
                    </div>
                </div>
            </div>
            
            {/* React-Bootstrap Modal for confirming removal of patient */}
            <Modal show={showRemoveModal} onHide={cancelRemovePatient} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Rimuovi paziente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {patientToRemove && (
                        <p style={{wordBreak: 'break-word'}}>
                            Stai per rimuovere il paziente: <strong>{patientToRemove.username} </strong>
                            dall'attività. Tutti i dati di log relativi a questo paziente
                            andranno persi. Vuoi continuare?
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{
                            backgroundColor: 'red',
                            borderColor: 'red',
                            color: 'white',
                            marginRight: '10px',
                        }}
                        onClick={cancelRemovePatient}>
                        Annulla
                    </Button>
                    <Button
                        style={{
                            backgroundColor: 'var(--filter-hovered-text-color)',
                            borderColor: 'var(--filter-hovered-text-color)',
                        }}
                        onClick={confirmRemovePatient}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ActivityInfo;
