import '../css/GeneralCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import '../css/ActivityBuilder.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function ExerciseInActivityBuilder( {canDelete, name, level} ) {
  return (
    <div className='activityInActivityBuilder'>
        {canDelete &&<button className='exerciseButtonsInActivityBuilder'>-</button>}
        <div className='exerciseNameInActivityBuilder'>{name}</div>
        <div className='exerciseButtonsInActivityBuilder'>
            <button className='levelButtons'>+</button>
            <div className='levelText'>{level}</div>
            <button className='levelButtons'>-</button>
        </div>

    </div>
   
  );
}

export default ExerciseInActivityBuilder;
