// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginWelcomeText{
    font-family: var(--main-font);
    font-size:xx-large;
    color: var(--main-blue);
    font-weight: 600;
}

.loginLabelText{
    font-family: var(--main-font);
    font-size: large;
}

.textCard{
    font-family: var(--main-font);
    font-size: medium;
    color: var(--main-white);
}

.labelCard{
    font-family: var(--main-font);
    font-size: large;
    color: var(--main-white);
}


p,a, label, h1, h2, h3, h4, button, input {
    text-wrap: wrap;
    word-break: break-all;
    font-family: var(--main-font);
}

.errorMessage{
    color: var(--error-message-color);
    font-family: var(--main-font);
    font-size: medium;
    align-self: center;
}

:root {
    --main-font: Fredoka;
    
  }
`, "",{"version":3,"sources":["webpack://./src/css/Text.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,wBAAwB;AAC5B;;;AAGA;IACI,eAAe;IACf,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,iCAAiC;IACjC,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;;EAEtB","sourcesContent":[".loginWelcomeText{\n    font-family: var(--main-font);\n    font-size:xx-large;\n    color: var(--main-blue);\n    font-weight: 600;\n}\n\n.loginLabelText{\n    font-family: var(--main-font);\n    font-size: large;\n}\n\n.textCard{\n    font-family: var(--main-font);\n    font-size: medium;\n    color: var(--main-white);\n}\n\n.labelCard{\n    font-family: var(--main-font);\n    font-size: large;\n    color: var(--main-white);\n}\n\n\np,a, label, h1, h2, h3, h4, button, input {\n    text-wrap: wrap;\n    word-break: break-all;\n    font-family: var(--main-font);\n}\n\n.errorMessage{\n    color: var(--error-message-color);\n    font-family: var(--main-font);\n    font-size: medium;\n    align-self: center;\n}\n\n:root {\n    --main-font: Fredoka;\n    \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
