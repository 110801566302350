import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import isLoggedIn from '../code/utils'
import axios from 'axios';
import { useState, useEffect, useCallback  } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Card from 'react-bootstrap/Card';
import UserImage from '../assets/manage_user.svg'
axios.defaults.withCredentials = true;

function Patients() {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/");
    } else {
      fetchPatients();
    }
  }, [navigate]);

  const fetchPatients =  async () =>{
    if(!isLoggedIn())
      navigate("/")
    else{
      //send req to logout
      const axiosConfig = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    }
      try {
        //setLoading(true)
        const response = await axios.post('http://localhost:5000/api/retrievePatients', {
          id: localStorage.getItem('user')
        }, axiosConfig);
       // setLoading(false);
        if (response.data.success) {
            console.log(localStorage.getItem('success'))
            console.log(response.data.patients)
            setPatients(response.data.patients)
        } else {
          switch(response.data.errorMess){
            case "DISCONNECT":
              localStorage.removeItem('user')
              navigate("/")
              break
            default:alert(response.data.errorMess) 
        }
        }
    } catch (error) {
        alert(error.errorMess) ;  
    }
    }
  }

  return (
    <>
    <Row className='flex-grow-1'></Row>
    <Container>
    <Row className='flex-grow-1'></Row>
    <Row className='justify-content-center'>
        <Col xs={11}>
        <Row>
        {patients.map((patient) => (
          <Col key={patient[0]}>
            <Card as="button" bg="primary" bsPrefix="patientsListElement" text="white" className='center'>
                <Card.Img bsPrefix="patientsImageListElement" className="cards-image-style" src={UserImage}/>
                <Card.Title >{patient[0]}</Card.Title>
            </Card>
            </Col>
        ))}
        </Row>
        </Col>
    </Row>
    </Container>
    <Row className='flex-grow-1'></Row>
    <Row className='centerRow'>
      <Col xs={8} md={8} xl={3} xxl={3} className='my-1 center flex-grow-1'>
          <Button size="lg">
                New Patient
            </Button>
        </Col>
    </Row>
    </>
  );
}

export default Patients;
