import '../css/GeneralCSS.css'
import '../css/CardCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function PatientBubble( {patientCode, canRemove} ) {
  return (
    <div className='patientBubbleArea'>
        {canRemove&&<button className='patientBubbleRemove'>-</button>}
        <div className='patientBubble'>
        <p className="patientBubbleCode"> {patientCode} </p>
        </div>
    </div>
   
  );
}

export default PatientBubble;
