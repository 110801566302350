import '../css/GeneralCSS.css'
import '../css/CardCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function Card( {text, date, exercisesNumber} ) {
  return (
    <div className='cardArea'>
      <p className='labelCard activityCardArea'> {text} </p>
      <div className='activityInfoArea'>
        <p className='labelCard activityInfo'> {date} </p>
        <p className='labelCard activityInfo'> {exercisesNumber} esercizi </p>
        <i className=' bi bi-arrow-right-circle-fill arrow'></i> 
      </div>
    </div>
   
  );
}

export default Card;
