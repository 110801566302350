import '../../css/GeneralCSS.css'
import '../../css/CardCSS.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import '../../css/BaseElements/Cards/Cards.css'
import OpenIconButton from '../Buttons/OpenIconButton';
import DeleteCardButton from "../Buttons/DeleteCardButton";

function PatientCard({text, info, onOpen, onDelete}) {
    return (
        <div className='activityCard'>
            <div className='patientCardName'> Paziente:</div>
            <div className='patientCardInfo'> {text} </div>
            
            {!info && <div className='patientCardOpenInconRow'>
                <DeleteCardButton onClick={onDelete}></DeleteCardButton>
                <OpenIconButton onClick={onOpen}/>
            </div>}
        </div>
    
    );
}

export default PatientCard;
