// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.therapistNameArea{
    color:var(--main-blue);
}
.logoutText{
    text-decoration: underline;
    color: var(--main-gray);
}
.logoutComponentArea{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: end;
    justify-content: center;
    
}`, "",{"version":3,"sources":["webpack://./src/css/LogoutCSS.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,0BAA0B;IAC1B,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;;AAE3B","sourcesContent":[".therapistNameArea{\n    color:var(--main-blue);\n}\n.logoutText{\n    text-decoration: underline;\n    color: var(--main-gray);\n}\n.logoutComponentArea{\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    align-items: end;\n    justify-content: center;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
