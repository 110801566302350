import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const AppWrapper = () => {
    useEffect(() => {
        document.documentElement.classList.add('notranslate');
        document.documentElement.setAttribute('translate', 'no');
        
        const metaTag = document.createElement('meta');
        metaTag.name = "google";
        metaTag.content = "notranslate";
        document.head.appendChild(metaTag);
        
        return () => {
            document.head.removeChild(metaTag);
        };
    }, []);
    
    return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AppWrapper />
    </React.StrictMode>
);

reportWebVitals();
