// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-blue: #3851C4;
    --main-white: #FFFFFF;
    --main-not-selected:  #FAFBFF;
    --main-light-blue: #D9DFFB;
    --shadow-color: gray;
    --main-gray: gray;
    --error-message-color: rgb(230, 42, 42);
    --main-dark-blue:#2D419C;


    --columnsActivityBuilderColor:#FAFBFF;
    --columnsActivityBuilderBorder:#D9DFFB;

    --activityInActivityBuilderColor: #D9DFFB;
    --activityInActivityBuilderBorder:#3851C4

  }


  `, "",{"version":3,"sources":["webpack://./src/css/Colors.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,6BAA6B;IAC7B,0BAA0B;IAC1B,oBAAoB;IACpB,iBAAiB;IACjB,uCAAuC;IACvC,wBAAwB;;;IAGxB,qCAAqC;IACrC,sCAAsC;;IAEtC,yCAAyC;IACzC;;EAEF","sourcesContent":[":root {\n    --main-blue: #3851C4;\n    --main-white: #FFFFFF;\n    --main-not-selected:  #FAFBFF;\n    --main-light-blue: #D9DFFB;\n    --shadow-color: gray;\n    --main-gray: gray;\n    --error-message-color: rgb(230, 42, 42);\n    --main-dark-blue:#2D419C;\n\n\n    --columnsActivityBuilderColor:#FAFBFF;\n    --columnsActivityBuilderBorder:#D9DFFB;\n\n    --activityInActivityBuilderColor: #D9DFFB;\n    --activityInActivityBuilderBorder:#3851C4\n\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
