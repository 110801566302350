import '../App.css';
import '../css/Home.css'
import HomeButton from'../BaseElements/HomeButton.js'
import SearchBar from'../BaseElements/SearchBar.js'
import LogoutComponent from '../BaseElements/LogoutComponent.js';
import Filter from '../BaseElements/Filter.js';
import NewCard from '../BaseElements/NewCard.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import PatientCard from '../BaseElements/PatientCard.js';
import ActivityCard from '../BaseElements/ActivityCard.js';

import { useNavigate } from 'react-router-dom'; 
import { useEffect } from 'react';
import { useState } from 'react';
import isLoggedIn from '../code/utils'
import axios from 'axios';



// CSS files
import '../css/Home.css'
import '../css/GeneralCSS.css'
import '../css/Text.css'
import '../css/Colors.css'
axios.defaults.withCredentials = true;

function HomePage() {
  const navigate = useNavigate();
  const [patients, setPatients] = useState(['Luca', 'Antonio', 'Ginevra', 'Zeus', 'Gertrude', 'Loki', 'Zelda']);
  const [activities, setActivities] = useState(['Luca', 'Antonio', 'Ginevra', 'Zeus', 'Gertrude', 'Loki', 'Zelda']);
  const [showListPatients, setShowListPatients] = useState(false);
  const [showListActivities, setShowListActivities] = useState(true);
  const [searchValue, setSearchValue]=useState('');
  
  useEffect(() => {
    if (!isLoggedIn()) {
      return;
      navigate("/");
    }
  }, [navigate]);


 


  const logout = async () =>{
    if(!isLoggedIn())
      navigate("/")
    else{
      //send req to logout
      const axiosConfig = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        } 
    }
      try {
        //setLoading(true)
        const response = await axios.get('http://localhost:5000/api/logOut', {}, axiosConfig);
       // setLoading(false);
        if (response.data.success) {
            //if in every case (besides errors) just log out
            console.log(localStorage.getItem('user'))
            localStorage.removeItem('user')
            console.log("removed? :"+ localStorage.getItem('user'))
            navigate("/")
        } else {
            alert(response.data.errorMess)  
        }
    } catch (error) {
        alert(error.errorMess) ;  
    }
    }
  }

  const UpdateSearchValue = (searchText) => {
    setSearchValue(searchText);
    console.log(searchText)
  }

  const showPatients = async () =>{
    if(!isLoggedIn())
      navigate("/")
    else{
      navigate("/patients")
    }
  }

  function manageFilter(patients,activities){
    setShowListActivities(activities)
    setShowListPatients(patients)
  }

   // Filter patients and activities based on search text
   const filteredPatients = patients.filter((patient) =>
    patient.toLowerCase().startsWith(searchValue.toLowerCase())
  );

  const filteredActivities = activities.filter((activity) =>
    activity.toLowerCase().startsWith(searchValue.toLowerCase())
  );

  return (
    <div className='fullPageContainer'>
    <div className='topArea'>
      <HomeButton/>
      <SearchBar  onChange={UpdateSearchValue}/>
      <LogoutComponent text={'Fabio Rossi'}/>
    </div>
    <div className='mainArea'>
    <div className='filterArea'>
      <Filter text={"Attività"} onClick={()=>{manageFilter(false,true)}}></Filter>
      <Filter text={"Pazienti"} onClick={()=>{manageFilter(true,false)}}></Filter>
    </div>
    <div className='cardsViewArea'>
    {showListPatients&&filteredPatients.map((patient) => (
         <PatientCard key={patient} text={patient}/>
        ))}


         {showListActivities&&filteredActivities.map((activity) => (
         <ActivityCard key={activity} text={activity} date={"undefined"} exercisesNumber={"4"}/>
        ))}
    </div>
    <div className='newCardArea'>
      <NewCard/>
    </div>
    </div>
    
    </div>
  );
}

export default HomePage;
