import '../css/GeneralCSS.css'
import '../css/CardCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function PatientCard( {text} ) {
  return (
    <div className='cardArea'>
      <p className='labelCard'> Codice Paziente: </p>
      <p className='textCard'> {text} </p>
      <div className='arrowDiv'>
        <i className=' bi bi-arrow-right-circle-fill arrow'></i> 
      </div>
    </div>
   
  );
}

export default PatientCard;
