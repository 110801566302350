// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.singleFilterArea{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--main-not-selected);
    padding: auto;
    margin: 10px; 
    border: transparent;
    border-radius: 20px;
    padding:5px;
    color: var(--main-blue);
}
.singleFilterArea:hover{
    background-color: var(--main-light-blue);
    color: var(--main-blue);
}

.filterText{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/css/FilterCSS.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,0CAA0C;IAC1C,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,uBAAuB;AAC3B;AACA;IACI,wCAAwC;IACxC,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".singleFilterArea{\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    background-color: var(--main-not-selected);\n    padding: auto;\n    margin: 10px; \n    border: transparent;\n    border-radius: 20px;\n    padding:5px;\n    color: var(--main-blue);\n}\n.singleFilterArea:hover{\n    background-color: var(--main-light-blue);\n    color: var(--main-blue);\n}\n\n.filterText{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    text-align: center;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
