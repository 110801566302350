import '../../css/GeneralCSS.css'
import '../../css/BaseElements/Cards/Cards.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import OpenIconButton from '../Buttons/OpenIconButton'
import 'bootstrap-icons/font/bootstrap-icons.css';
import DeleteCardButton from "../Buttons/DeleteCardButton";
function ActivityCard( {text, exercisesNumber, onClick, onDelete} ) {
  return (
    <div className='activityCard'>
      <p className='activityCardName'> {text} </p>
      <div className='activityCardDataArea'>
        <p className='activityCardData'> {exercisesNumber} esercizi </p>
        <DeleteCardButton onClick={onDelete}></DeleteCardButton>
        <OpenIconButton onClick={onClick}/>
      </div>
    </div>
   
  );
}

export default ActivityCard;
