import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useEffect} from 'react';
import axios from 'axios';

import allAnimalsImage from '../assets/TheraLogin.svg'


// CSS files
import '../css/LoginCSS.css'
import '../css/GeneralCSS.css'
import '../css/Text.css'
import '../css/Colors.css'


axios.defaults.withCredentials = true;

function LoginPage() {
    
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMess, setErrorMess] = useState('');
    const [isMdOrLess, setIsMdOrLess] = useState(window.innerWidth <= 768);
    
    const handleResize = () => {
        setIsMdOrLess(window.innerWidth < 768);
    };
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    
    const login = async (event) => {
        event.preventDefault();
        setShowError(false);
        
        if (username == null || password == null || username.length === 0 || password.length === 0) {
            setErrorMess("Per favore inserisci username e password")
            setShowError(true);
            return
        }
        
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            setLoading(true)
            const response = await axios.post('http://localhost:5001/api/patientsLogIn', {
                                                  username: username,
                                                  password: password,
                                              }, axiosConfig
            );
            setLoading(false);
            if (response.data.success) {
                localStorage.setItem('user', response.data.id);
                navigate("/home")
            } else {
                setErrorMess(response.data.errorMess)
                setLoading(false);
                setShowError(true);
            }
        } catch (error) {
            setErrorMess(error.errorMess);
            setLoading(false);
            setShowError(true);
        }
    }
    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    
    return (
        <div className='generalBackground'>
                <div className='loginImageCol'>
                    <div className='loginImage'/>
                </div>
                <div className='loginFormCol'>
                    <div className='loginComponent'>
                    <div className='loginForm'>
                        <p className=' loginWelcomeText '> Bentornato! </p>
                        <label className='loginLabelText'> User ID </label>
                        <input className='loginInputArea' onChange={handleUsernameChange}/>
                        <label className='loginLabelText'> Password </label>
                        <input className="loginInputArea" onChange={handlePasswordChange}/>
                        <button className="loginButton" type="submit" disabled={isLoading} onClick={login}>
                            {!isLoading && ('Login')}
                            {isLoading && (<Spinner animation="border" variant="light"/>)}
                        </button>
                </div>
                {showError && (<p className='errorMessage'>{errorMess}</p>)}
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
