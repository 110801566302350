import '../css/GeneralCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import '../css/LogoutCSS.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function LogoutComponent( {text} ) {
  return (
    <div className='logoutComponentArea'>
      <p className='therapistNameArea'> {text} </p>
      <a className='logoutText'>Logout</a>  
    </div>
   
  );
}

export default LogoutComponent;
