import '../css/GeneralCSS.css'
import '../css/CardCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function NewCard( {text} ) {
  return (
    <div className='newCardButtonArea'>
      <i className="bi bi-plus-circle-fill plusIcon"></i>
      <p className='newCardText'> LA </p>
    </div>
   
  );
}

export default NewCard;
