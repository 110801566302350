import '../css/GeneralCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import '../css/FilterCSS.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function Filter( {text, onClick} ) {
  return (
    <button onClick={onClick}className='singleFilterArea'>
      <p className='filterText'> {text} </p>
    </button>
   
  );
}

export default Filter;
