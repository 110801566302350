import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';

function AddUser() {
  return (
    <Container  className=' center '>
        <Col xs={10} md={7} xl={6} xxl={6} className='center canScroll'>
          <Form className='loginForm center '>
                <Form.Group className=' loginFormComponent'controlId='newUserUsername'>
                  <Form.Label> ID  </Form.Label>
                  <Form.Control type="username" placeholder='ID'/>
                </Form.Group>
                <Form.Group className=' loginFormComponent' controlId='newUserPsw'>
                  <Form.Label> Password </Form.Label>
                  <Form.Control type="username" placeholder='password'/>
                </Form.Group>
                <Form.Group className=' loginFormComponent' controlId='newUserBirthDate'>
                  <Form.Label> Data di nascita </Form.Label>
                  <Form.Control type="date" placeholder='dd-mm-yyyy'/>
                </Form.Group>
               
                <Form.Group className='loginFormComponent'controlId='newUserLV'>
                  <Row>
                  <Form.Label> Livello  </Form.Label>
                  <Col xs={2} md={2} xl={2} xxl={2} className='center'>
                  <Form.Control className="center" type="username" placeholder='1' maxlength="1"/>
                  </Col>
                  </Row>
                  
                </Form.Group>
                
                <Form.Group className="loginFormComponent" controlId="newUserInterests">
                  <Form.Label>Interessi</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
                <Button variant="primary" type="submit" className='mt-3'>
                  Crea utente
                </Button>
            </Form>
        </Col>
    </Container>
  );
}

export default AddUser;
